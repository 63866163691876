<template>
  <div class="container">
    <div class="row ">
      <img src="./iheader.png" alt="header" width="100%" />
    </div>
   
    <h2 class="mt-3 text-center border p-2"><b>INVOICE </b></h2>

    <table class="table table-sm p-0">
      <tr>
        <td>
          <h4>IMES GLOBAL INC</h4>
          <h4>Pasay City, Philippines</h4>
          <h4>Phone 02-853-3027, 556-4934</h4>
          <h4>Vat Reg. No. TIN 006-917-129-000</h4>
        </td>

        <td>
          <h4>Invoice No. {{ invoice.id }}</h4>
          <h4>Invoice Date. {{ invoice.invoice_start }}</h4>
          <h4>Customer ID. CA014</h4>
        </td>
      </tr>
      <br /><br />
      <tr>
        <td>
          <h4>Acc. No. 0374022504</h4>
          <h4>Bank of the Philippine Island (BP)</h4>
          <h4>0037 Baclaran Redemptorist Passav City.</h4>
          <h4>Philippines 1300</h4>
          <h4>SWIFT CODE: BOPIPHMM</h4>
        </td>
        <td>
          <h4>Mode of Pay. "hard_code"</h4>
        </td>
      </tr>
    </table>

    <table class="table-bdr table table-sm p-0 m-0">
      <tr>
        <th class="float-center">SNo.</th>
        <th class="float-center">Description</th>
        <th class="float-center">Quantity</th>
        <th class="float-center">Price</th>
        <th class="float-center">Currency</th>
        <th class="float-center">Total Amount</th>
      </tr>

      <tr class="list" v-for="(value, index) in items" :key="index">
        <td>{{ index + 1 }}</td>
        <td>{{ value.item }}</td>
        <td>{{ value.quantity }}</td>
        <td>{{ comma(value.price) }}</td>
        <td>SAR</td>
        <td>{{ comma(value.amount) }}</td>
      </tr>
      <tr>
        <td></td>
        <td colspan="4" class="float-center">Total</td>
        <td class="float-center">{{ invoice_total_amount }}</td>
      </tr>
      <tr>
        <td></td>
        <td colspan="4" class="text-right">Total Amount DUE (SAR)</td>
        <td class="float-center">{{ invoice_total_amount }}</td>
      </tr>

      <tr>
        <td></td>
        <td colspan="4" class="text-right">Less: Withholding Tax 15%</td>
        <td class="float-center">{{ invoice_vat_amount }}</td>
      </tr>
      <tr>
        <td></td>
        <td colspan="4" class="text-right">Net Amount Payable (SAR)</td>
        <td class="fs-row float-center">
          <h4 class="border-top border-bottom w-50 p-2">net amount payable</h4>
        </td>
      </tr>

      <tr>
        <td colspan="6" class="float-center">({{ invoice.amount_words }})</td>
      </tr>
    </table>

    <br />
    <br />
    <br><br><br>
    <p style="width: 200px; border-top: solid 2px"></p>
    <h3><b> Mr. YASER YOSUF </b></h3>
    <h4>Finance Manager</h4>
    <h4>Al-Busaili Co. Riyadh - KSA</h4>
    <br><br><br>
    <div class="row">
      <img src="./ifooter.png" alt="header" width="100%">
    </div>
  </div>
</template>
<script>
import HRMService from "../../../../services/hrm-service";
const VueQrcode = require("@chenfengyuan/vue-qrcode");

const converter = require("number-to-words");

export default {
  name: "SalesInvoicePhilip",
  components: {
    VueQrcode: VueQrcode,
  },
  data: function () {
    return {
      selected_expat: "progress",
      option_expat: [
        {
          text: "Progress",
          value: "progress",
        },
        {
          text: "Retention",
          value: "retention",
        },
        {
          text: "Other",
          value: "other",
        },
      ],
      selected_approval: "yes",
      option_approval: [
        {
          text: "Yes",
          value: "yes",
        },
        {
          text: "No",
          value: "no",
        },
      ],
      invoice: {},
      items: [],
      invoice_total_amount: 0,
      invoice_vat_amount: 0,
      invoice_gross_amount: 0,
      invoice_rentention_amount: 0,
      invoice_net_amount: 0,
      // amount_words: "",
      hc_address: "",
    };
  },
  mounted() {
    this.retrieve(this.$route.params.id);
  },
  methods: {
    trigger(t) {
      alert(t);
    },
    toggle() {
      this.show = !this.show;
    },
    comma(data) {
      //  return data
      return Number(parseFloat(data).toFixed(2)).toLocaleString("en", {
        minimumFractionDigits: 2,
      });
    },
    retrieve(id) {
      HRMService.getOrderItems(id)
        .then((response) => {
          this.invoice = response.data["sales"][0];
          this.items = response.data["items"];

          this.invoice_total_amount = this.comma(this.invoice.total_amount);
          this.invoice_vat_amount = this.comma(this.invoice.vat_amount);
          this.invoice_gross_amount = this.comma(this.invoice.gross_amount);
          this.invoice_rentention_amount = this.comma(
            this.invoice.retention_amount
          );
          this.invoice_net_amount = this.comma(this.invoice.net_amount);

          if (this.invoice.crcc_aproval == "CRCCD") {
            this.hc_address = "Box # 2396, Riyadh – 11451";
          } else if (this.invoice.crcc_aproval == "WRDD") {
            this.hc_address = "Jeddah";
          } else if (this.invoice.crcc_aproval == "WRDD-Y") {
            this.hc_address = "Admin. Bldg. RM – 153, P.B. 30020, YANBU";
          } else if (this.invoice.crcc_aproval == "ERDD J GP Dep") {
            this.hc_address = "Box # 1500, Dhahran – 31311";
          } else {
            this.hc_address = "";
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
<style scoped>
.fs-row {
  display: flex;
  align-items: center;
  justify-content: center;
}

.center {
  width: 1100px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.border {
  border: solid 2px black !important;
}
.word {
  text-transform: capitalize;
}
.fa-bold {
  font-weight: bold;
}

.float-center {
  text-align: center;
}

.box {
  width: 20px;
  height: 25px;
  border: 1px solid black;
}
.line-height {
  line-height: 0.5px;
}

.border-top {
  border-top: solid 1px black !important;
}

.border-bottom {
  border-bottom: 3px double black !important;
}

.table th {
  font-size: 16px !important;
}

.table td {
  margin: 4px;
  font-size: 16px !important;
  font-weight: bold;
}
.table-bdr {
  border: 2px solid #000;
}
.table-bdr th {
  border: 1px solid #000;
  /* border:none !important; */
}
.table-bdr td {
  /* border: 1px solid #000; */
  /* border:none !important; */
}
.list td {
  font-size: 16px !important;
  text-align: center;
}
</style>