var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_vm._m(0),_vm._m(1),_c('table',{staticClass:"table table-sm p-0"},[_c('tr',[_vm._m(2),_c('td',[_c('h4',[_vm._v("Invoice No. "+_vm._s(_vm.invoice.id))]),_c('h4',[_vm._v("Invoice Date. "+_vm._s(_vm.invoice.invoice_start))]),_c('h4',[_vm._v("Customer ID. CA014")])])]),_c('br'),_c('br'),_vm._m(3)]),_c('table',{staticClass:"table-bdr table table-sm p-0 m-0"},[_vm._m(4),_vm._l((_vm.items),function(value,index){return _c('tr',{key:index,staticClass:"list"},[_c('td',[_vm._v(_vm._s(index + 1))]),_c('td',[_vm._v(_vm._s(value.item))]),_c('td',[_vm._v(_vm._s(value.quantity))]),_c('td',[_vm._v(_vm._s(_vm.comma(value.price)))]),_c('td',[_vm._v("SAR")]),_c('td',[_vm._v(_vm._s(_vm.comma(value.amount)))])])}),_c('tr',[_c('td'),_c('td',{staticClass:"float-center",attrs:{"colspan":"4"}},[_vm._v("Total")]),_c('td',{staticClass:"float-center"},[_vm._v(_vm._s(_vm.invoice_total_amount))])]),_c('tr',[_c('td'),_c('td',{staticClass:"text-right",attrs:{"colspan":"4"}},[_vm._v("Total Amount DUE (SAR)")]),_c('td',{staticClass:"float-center"},[_vm._v(_vm._s(_vm.invoice_total_amount))])]),_c('tr',[_c('td'),_c('td',{staticClass:"text-right",attrs:{"colspan":"4"}},[_vm._v("Less: Withholding Tax 15%")]),_c('td',{staticClass:"float-center"},[_vm._v(_vm._s(_vm.invoice_vat_amount))])]),_vm._m(5),_c('tr',[_c('td',{staticClass:"float-center",attrs:{"colspan":"6"}},[_vm._v("("+_vm._s(_vm.invoice.amount_words)+")")])])],2),_c('br'),_c('br'),_c('br'),_c('br'),_c('br'),_c('p',{staticStyle:{"width":"200px","border-top":"solid 2px"}}),_vm._m(6),_c('h4',[_vm._v("Finance Manager")]),_c('h4',[_vm._v("Al-Busaili Co. Riyadh - KSA")]),_c('br'),_c('br'),_c('br'),_vm._m(7)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('img',{attrs:{"src":require("./iheader.png"),"alt":"header","width":"100%"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('h2',{staticClass:"mt-3 text-center border p-2"},[_c('b',[_vm._v("INVOICE ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('h4',[_vm._v("IMES GLOBAL INC")]),_c('h4',[_vm._v("Pasay City, Philippines")]),_c('h4',[_vm._v("Phone 02-853-3027, 556-4934")]),_c('h4',[_vm._v("Vat Reg. No. TIN 006-917-129-000")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_c('h4',[_vm._v("Acc. No. 0374022504")]),_c('h4',[_vm._v("Bank of the Philippine Island (BP)")]),_c('h4',[_vm._v("0037 Baclaran Redemptorist Passav City.")]),_c('h4',[_vm._v("Philippines 1300")]),_c('h4',[_vm._v("SWIFT CODE: BOPIPHMM")])]),_c('td',[_c('h4',[_vm._v("Mode of Pay. \"hard_code\"")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',{staticClass:"float-center"},[_vm._v("SNo.")]),_c('th',{staticClass:"float-center"},[_vm._v("Description")]),_c('th',{staticClass:"float-center"},[_vm._v("Quantity")]),_c('th',{staticClass:"float-center"},[_vm._v("Price")]),_c('th',{staticClass:"float-center"},[_vm._v("Currency")]),_c('th',{staticClass:"float-center"},[_vm._v("Total Amount")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td'),_c('td',{staticClass:"text-right",attrs:{"colspan":"4"}},[_vm._v("Net Amount Payable (SAR)")]),_c('td',{staticClass:"fs-row float-center"},[_c('h4',{staticClass:"border-top border-bottom w-50 p-2"},[_vm._v("net amount payable")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',[_c('b',[_vm._v(" Mr. YASER YOSUF ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('img',{attrs:{"src":require("./ifooter.png"),"alt":"header","width":"100%"}})])
}]

export { render, staticRenderFns }